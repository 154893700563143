import React, { useEffect, useState } from 'react';
import axios from 'axios';

function FullTable() {
  const [solicitudes, setSolicitudes] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: '' });
  const [searchTermOC, setSearchTermOC] = useState('');
  const [searchTermSolicitud, setSearchTermSolicitud] = useState('');
  const [filterBodega, setFilterBodega] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [sumaResultados, setSumaResultados] = useState(null);
  const [searchTermDescripcion, setSearchTermDescripcion] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');

    axios.get('https://api.cnagro.cl/asignaciones', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        console.log('Tipo de datos recibidos:', typeof response.data);
        console.log('Datos recibidos:', response.data);
        if (Array.isArray(response.data)) {
          console.log('Primer elemento del diccionario:', response.data[0]);
          setSolicitudes(response.data);
        } else {
          console.error('La respuesta de la API no es una lista de diccionarios:', response.data);
        }
      })
      .catch(error => {
        console.error('Hubo un error al obtener las asignaciones:', error);
      });
  }, []);

  const sortedItems = React.useMemo(() => {
    if (!Array.isArray(solicitudes)) {
      console.error('solicitudes no es un array:', solicitudes);
      return [];
    }

    console.log('Solicitudes antes de filtrar y ordenar:', solicitudes);

    return solicitudes
      .filter(solicitud =>
        solicitud["Numero OC"].toString().includes(searchTermOC) &&
        solicitud["N° Solicitud"].toString().includes(searchTermSolicitud) &&
        solicitud["Descripción Producto"].toString().includes(searchTermDescripcion) &&
        (filterBodega === '' || solicitud.bodega === filterBodega)
      )
      .sort((a, b) => {
        if (sortConfig.key && sortConfig.direction) {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
        }
        return 0;
      });
  }, [solicitudes, sortConfig, searchTermOC, searchTermSolicitud, searchTermDescripcion, filterBodega]);

  const requestSort = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    } else if (sortConfig.key === key && sortConfig.direction === 'descending') {
      direction = '';
    }
    setSortConfig({ key, direction });
  };

  const handleGetSuma = () => {
    if (!selectedDate || !filterBodega) {
      alert('Seleccione una bodega y una fecha para obtener la suma.');
      return;
    }
    axios.get(`https://api.cnagro.cl/suma-cantidades`, {
      params: {
        fecha: selectedDate,
        bodega: filterBodega
      }
    }).then(response => {
      setSumaResultados(response.data.suma_total);
    }).catch(error => {
      console.error('Hubo un error al obtener la suma de cantidades:', error);
      setSumaResultados(null);
    });
  };

  return (
    <div className="flex justify-center mt-10">
      <div className="overflow-x-auto shadow-md sm:rounded-lg w-full h-screen m-10">
        <div className="inline-block min-w-full align-middle h-full">
          <div className="flex gap-4 mb-4">
            <input
              type="text"
              placeholder="Buscar por Número de OC"
              className="p-2 border rounded"
              value={searchTermOC}
              onChange={(e) => setSearchTermOC(e.target.value)}
            />
            <input
              type="text"
              placeholder="Buscar por Número de Solicitud"
              className="p-2 border rounded"
              value={searchTermSolicitud}
              onChange={(e) => setSearchTermSolicitud(e.target.value)}
            />
            <input
              type="text"
              placeholder="Buscar por Descripción Producto"
              className="p-2 border rounded"
              value={searchTermDescripcion}
              onChange={(e) => setSearchTermDescripcion(e.target.value)}
            />
            {/* Dropdown para seleccionar la bodega */}
            <select
              className="p-2 border rounded"
              value={filterBodega}
              onChange={(e) => setFilterBodega(e.target.value)}
            >
              <option value="">Todas las bodegas</option>
              <option value="PENCO">PENCO</option>
              <option value="SAN ANTONIO">SAN ANTONIO</option>
              <option value="PUERTO MONTT">PUERTO MONTT</option>
              <option value="No asignada">No asignada</option>
            </select>
            <input
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
            <button onClick={handleGetSuma}>Calcular Suma</button>
          </div>
          <div className="overflow-y-auto h-full">
            <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700">
              <thead className="bg-cna-verde-oscuro dark:bg-gray-700 sticky top-0 z-10">
                <tr>
                  {['Fecha Estimada de Despacho', 'Fecha Solicitud', 'Nombre Cliente', 'Código Producto', 'Descripción Producto', 'Cantidad en Solicitud', 'Comuna', 'Bodega', 'Dirección', 'Numero OC', 'N° Solicitud'].map((key) => (
                    <th
                      key={key}
                      scope="col"
                      className="p-4 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                      onClick={() => requestSort(key)}
                    >
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-800">
                {sortedItems.map((solicitud, index) => (
                  <tr key={index}>
                    <td className="p-4 text-sm text-gray-500 dark:text-gray-400">{solicitud.fecha_despacho}</td>
                    <td className="p-4 text-sm text-gray-500 dark:text-gray-400">{solicitud["Fecha Solicitud"]}</td>
                    <td className="p-4 text-sm text-gray-500 dark:text-gray-400">{solicitud["Nombre Cliente"]}</td>
                    <td className="p-4 text-sm text-gray-500 dark:text-gray-400">{solicitud["Código Producto"]}</td>
                    <td className="p-4 text-sm text-gray-500 dark:text-gray-400">{solicitud["Descripción Producto"]}</td>
                    <td className="p-4 text-sm text-gray-500 dark:text-gray-400">{solicitud["Cantidad en Solicitud"]}</td>
                    <td className="p-4 text-sm text-gray-500 dark:text-gray-400">{solicitud.Comuna}</td>
                    <td className="p-4 text-sm text-gray-500 dark:text-gray-400">{solicitud.bodega}</td>
                    <td className="p-4 text-sm text-gray-500 dark:text-gray-400">{solicitud.Dirección}</td>
                    <td className="p-4 text-sm text-gray-500 dark:text-gray-400">{solicitud["Numero OC"]}</td>
                    <td className="p-4 text-sm text-gray-500 dark:text-gray-400">{solicitud["N° Solicitud"]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FullTable;
