import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SearchView from '../components/SearchView/SearchView';
import TableView from '../components/TableView/TableView';
import FullTable from '../components/TableView/FullTable';

function App() {
  return (
    <div
      className="min-h-screen flex flex-col"
      style={{ backgroundImage: `url('/irrigacion.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      <Router>
        <div className="flex-grow">
          <Routes>
            <Route path="/search" element={<SearchView />} />
            <Route path="/table" element={<TableView />} />
            <Route path="/fulltable" element={<FullTable />} />
            <Route path="/" element={<Navigate to="/search" replace />} />
          </Routes>
        </div>
        <footer className="footer fixed bottom-0 w-full bg-gray-800 text-white text-right py-2">
          © 2024 CNA Chile SPA. Todos los derechos reservados.
        </footer>
      </Router>
    </div>
  );
}

export default App;
