import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Asegúrate de importar el CSS de Toastify

function TableView() {
  const location = useLocation();
  const navigate = useNavigate();
  const [solicitudes, setSolicitudes] = useState([]);

  useEffect(() => {
    if (location.state && location.state.data) {
      setSolicitudes(location.state.data); // Usar directamente los datos pasados a través de state
    }
  }, [location]);

  const calculateMaxDate = (fechaDespacho) => {
    if (fechaDespacho === "Por definir") {
      return "Por definir";
    }

    const fechaDespachoDate = new Date(fechaDespacho);
    let fechaMaxima = new Date(fechaDespachoDate);
    fechaMaxima.setDate(fechaMaxima.getDate() + 5); // Sumar dos días hábiles

    // Si es sábado, sumar dos días más para obtener el lunes siguiente
    if (fechaMaxima.getDay() === 6) {
      fechaMaxima.setDate(fechaMaxima.getDate() + 2);
    }
    // Si es domingo, sumar un día más para obtener el lunes siguiente
    else if (fechaMaxima.getDay() === 0) {
      fechaMaxima.setDate(fechaMaxima.getDate() + 1);
    }

    return fechaMaxima.toLocaleDateString('es-ES');
  };

  return (
    <div className="table-view">
      <ToastContainer />

      <div className="fixed top-0 left-0 w-full bg-white p-4 flex justify-between items-center shadow-md" style={{ height: '80px', zIndex: 1000 }}>
        <img
          src="/CNA_black.png"
          alt="Logo CNA"
          className="h-20 cursor-pointer"
          onClick={() => navigate('/')}
        />
        <button
          onClick={() => navigate('/search')}
          style={{ backgroundColor: 'rgb(128, 182, 26)' }}
          className="px-4 py-2 text-white font-bold rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
        >
          Regresar al inicio
        </button>
      </div>
      
      <div className="mt-20" style={{ paddingTop: '100px', display: 'flex', justifyContent: 'center' }}>
        <div className="bg-white bg-opacity-75 p-3 m-4 rounded shadow-md w-full max-w-7xl overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700 rounded">
            <thead className="sticky top-0 z-10" style={{ backgroundColor: 'rgb(0, 149, 58)' }}>
              <tr>
                <th className="p-4 text-left text-xs font-medium text-white uppercase tracking-wider">Fecha Estimada de Despacho</th>
                <th className="p-4 text-left text-xs font-medium text-white uppercase tracking-wider">Cliente</th>
                <th className="p-4 text-left text-xs font-medium text-white uppercase tracking-wider">Descripción del Producto</th>
                <th className="p-4 text-left text-xs font-medium text-white uppercase tracking-wider">Cantidad</th>
                <th className="p-4 text-left text-xs font-medium text-white uppercase tracking-wider">Comuna</th>
                <th className="p-4 text-left text-xs font-medium text-white uppercase tracking-wider">Dirección</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-800">
              {solicitudes.map((solicitud, index) => (
                <tr key={index} className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600">
                  <td className="p-4 text-sm text-black dark:text-gray-400 font-extrabold">
                    {solicitud.fecha_despacho !== "Por definir" ? 
                      `${new Date(solicitud.fecha_despacho).toLocaleDateString('es-ES')} - ${calculateMaxDate(solicitud.fecha_despacho)}`
                      : "Por definir"
                    }
                  </td>
                  <td className="p-4 text-sm text-black dark:text-gray-400">{solicitud["Nombre Cliente"]}</td>
                  <td className="p-4 text-sm text-black dark:text-gray-400">{solicitud["Descripción Producto"]}</td>
                  <td className="p-4 text-sm text-black dark:text-gray-400">{solicitud["Cantidad en Solicitud"]}</td>
                  <td className="p-4 text-sm text-black dark:text-gray-400">{solicitud.Comuna}</td>
                  <td className="p-4 text-sm text-black dark:text-gray-400">{solicitud.Dirección}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TableView;
